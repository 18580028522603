import Switch from 'anchor-ui/switch';
import { metadataInterface } from '../../interfaces/metadataInterface';
import Select from 'anchor-ui/select';
import MenuItem from 'anchor-ui/menu-item';

const PrivacyPage = ({
  visibility,
  changeVisibility,
  userProfile,
  handleUpdateUserMetadata,
}: {
  visibility: string;
  changeVisibility: (visibility: string) => void;
  userProfile: metadataInterface;
  handleUpdateUserMetadata: (newMetadata: metadataInterface) => void;
}) => {

  const handleChange = (event: object, value: string) => {
    const newMetadata = { ...userProfile };
    newMetadata.callStatus = value;
    handleUpdateUserMetadata(newMetadata);
  };

  return (
    <div>
      <h2>Privacy</h2>
      <section className="account-input">

        <Select
          label="Profiel zichtbaarheid"
          value={visibility}
          onChange={(event: object, value: string) => {
            changeVisibility(value);

            // Ensure settings object exists
            const newMetadata = { ...userProfile };
            if (!newMetadata.settings) {
              newMetadata.settings = {}; // Initialize settings if undefined
            }
            newMetadata.settings.profileVisibility = value;

            handleUpdateUserMetadata(newMetadata);
          }}
        >
          <MenuItem text="Openbaar" value="public" />
          <MenuItem text="Privé" value="private" />
          <MenuItem text="Alleen vrienden" value="friends" />
        </Select>
        {
          visibility === "private" ? (
            <p>Je profiel is privé. Andere gebruikers kunnen jouw profiel niet bekijken.</p>
          ) : visibility === "friends" ? (
            <p>Je profiel is alleen zichtbaar voor vrienden.</p>
          ) : (
            <p>Je profiel is zichtbaar voor iedereen.</p>
          )}

        <br />
        <section className="setting-select">
          <Select
            label="Wie mag je bellen?"
            value={userProfile.callStatus ?? "Iedereen"}
            onChange={handleChange}
          >
            <MenuItem text="Iedereen" value="" />
            <MenuItem text="Alleen vrienden" value="call_only_friends" />
            <MenuItem text="Niemand" value="dnd" />
          </Select>
        </section>
      </section>
    </div>
  );
}

export default PrivacyPage;